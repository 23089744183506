.BasicSwiper {
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    margin: 0 2px !important;
    background-color: #d7d9da !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #686f73 !important;
    opacity: 1 !important;
  }

  .swiper-pagination {
    position: relative;
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    right: unset !important;
  }
}
