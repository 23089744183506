.TheProgress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5);

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    $size: 64px;
    width: $size;
    height: $size;
    border-radius: 8px;
    background-color: #000;
    background-image: url("~src/assets/imgs/progress.gif");
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
  }
}
