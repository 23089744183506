.EditableContent {
  font-size: 16px;
  line-height: 2;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // @include font-bold;
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
    line-height: 1.5;
  }

  h2 {
    font-size: 20px;
    line-height: 1.5;
  }

  dl {
    dt {
      font-weight: bold;
    }
  }

  ul {
    list-style: decimal inside;
    ul {
      list-style: upper-roman inside;
      ul {
        list-style: lower-roman inside;
      }
    }
  }

  ul {
    li {
      & + li {
        margin-top: 1em;
      }
    }
  }

  li {
    font-size: 1em;

    > ul {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }

    li {
      font-size: 0.96em;
      margin-left: 2em;
    }
  }

  * + section {
    margin-top: 4em;
  }

  * + h1,
  * + h2 {
    margin-top: 1em;
  }

  * + p {
    margin-top: 0.5em;
  }

  * + table,
  * + ul,
  * + dl {
    margin-top: 1.5em;
  }

  a {
    text-decoration: underline;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  * + img {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  table {
    border: solid 1px #000000;
    border-collapse: collapse;
    font-size: 12px;
    border-spacing: 2em;

    @include sp-layout {
      @apply text-2xs;
      border-spacing: 1em;
    }
    tr {
      td,
      th {
        padding: 1em;
        border: 1px solid #000;
      }

      td {
        &:nth-child(1),
        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 50%;
        }
      }
    }
  }
}
