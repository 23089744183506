.UserIcon {
  $root: &;
  display: flex;
  align-items: center;
  @apply font-bold;

  &.horizontal {
    #{$root}__name {
      margin-left: 0.5em;
      text-overflow: ellipsis;
    }
  }

  &.vertical {
    flex-direction: column;

    #{$root}__name {
      margin-top: 0.4em;
    }
  }

  &.small {
    font-size: 12px;
    #{$root}__icon {
      $size: 24px;
      width: #{$size};
      height: #{$size};

      @include sp-layout {
        $size: 16px;
        width: #{$size};
        height: #{$size};
      }
    }
  }

  &.regular {
    font-size: 12px;
    #{$root}__icon {
      $size: 24px;
      width: #{$size};
      height: #{$size};
    }
  }

  &.medium {
    font-size: 12px;
    #{$root}__icon {
      @include sp-layout {
        $size: 32px;
        width: #{$size};
        height: #{$size};
      }

      @include pc-layout {
        $size: 44px;
        width: #{$size};
        height: #{$size};
      }
    }
  }

  &.large {
    font-size: 24px;

    #{$root}__icon {
      $size: 100px;
      width: #{$size};
      height: #{$size};

      @include pc-layout {
        $size: 160px;
        width: #{$size};
        height: #{$size};
      }
    }
  }

  &__icon {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 9999px;
    overflow: hidden;
  }
}
