.HorizontalSingleRowSwiper {
  &.hasFade {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 60px;
      height: 100%;
      background: rgb(244, 245, 246);
      background: -moz-linear-gradient(90deg, rgba(244, 245, 246, 0) 0%, rgba(244, 245, 246, 1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(244, 245, 246, 0) 0%, rgba(244, 245, 246, 1) 100%);
      background: linear-gradient(90deg, rgba(244, 245, 246, 0) 0%, rgba(244, 245, 246, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4f5f6",endColorstr="#f4f5f6",GradientType=1);
      z-index: 1;
      pointer-events: none;
    }
  }

  &.swiper-container {
    @include sp-layout {
      padding: 0 12px;
    }
  }
}
