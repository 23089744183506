@mixin pc-layout {
  @media (min-width: $pc_min_width) {
    @content;
  }
}

@mixin sp-layout {
  @media (max-width: ($pc_min_width - 1)) {
    @content;
  }
}

// 375px以下の端末のレイアウト
@mixin ex-sp-layout {
  @media (max-width: (375px - 1)) {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
