.ProductBalloon {
  $root: &;
  position: relative;
  color: #fff;
  cursor: pointer;

  max-width: 280px;

  &__body {
    background-color: rgba(0, 0, 0, 0.9);
  }

  &__tail {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 9px 0 9px;
    border-color: #000 transparent transparent transparent;
    left: 0;
  }
}
