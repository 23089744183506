.ListItem {
  $root: &;

  &__imgs {
    &.multiple {
      gap: 1px;
      display: grid;
      grid-template-columns: 1.618fr 1fr;
      grid-template-rows: 1.618fr 1fr;

      > div {
        &:nth-child(1) {
          grid-row: 1 / 3;
          grid-column: 1 / 2;
        }

        &:nth-child(2) {
          grid-row: 1 / 2;
          grid-column: 2 / 3;
        }

        &:nth-child(3) {
          grid-row: 2 / 3;
          grid-column: 2 / 3;
        }
      }
    }
  }
}
