.Tab {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  transition: opacity linear 0.23s;
  color: #666;

  + .Tab {
    margin-left: 24px;
    @include sp-layout {
      margin-left: 16px;
    }
  }

  &.current {
    @apply font-bold;
    color: #000;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: #000;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &:hover {
    @include pc-layout {
      color: #000;
    }
  }

  p {
    padding-bottom: 10px;
  }

  a {
    text-decoration: none;
    padding-bottom: 10px;
  }
}
