.BtnMore {
  display: inline-block;
  text-align: center;
  padding: 0 1em;
  white-space: nowrap;
  transition: background-color linear 0.23s;
  @apply text-black;
  @apply text-2xs;
  border-radius: 9999px;
  background-color: #efefef;
  text-decoration: none;

  @include sp-layout {
    $height: 24px;
    height: $height;
    line-height: $height;
  }

  @include pc-layout {
    $height: 24px;
    height: $height;
    line-height: $height;
  }

  &.yellow {
    @apply bg-yellow-500;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}
