.ContainerAuth {
  position: relative;
  background-color: #eee;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */

  @include sp-layout {
    padding: 32px 16px 32px 16px;
  }

  @include pc-layout {
    padding: 80px 80px 100px 80px;
  }
}
