.BtnSns {
  position: relative;
  display: block;
  $size: 32px;
  width: $size;
  height: $size;
  border-radius: 9999px;

  &:hover {
    text-decoration: none;
  }

  &:before {
    @apply text-black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include sp-layout {
      font-size: 22px;
    }

    @include pc-layout {
      font-size: 24px;
    }
  }

  &.icon-Facebook {
    &:before {
      content: "\e914";
      @include sp-layout {
        font-size: 24px;
      }
    }
  }

  &.icon-Twitter {
    &:before {
      content: "\e913";
      @include sp-layout {
        font-size: 24px;
      }
    }
  }

  &.icon-Instagram {
    &:before {
      content: "\e902";
    }
  }

  &.icon-Youtube {
    &:before {
      content: "\e903";
      @include sp-layout {
        font-size: 18px;
      }

      @include pc-layout {
        font-size: 18px;
      }
    }
  }
}
