.ErrorView {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    height: auto;

    @include sp-layout {
      width: 160px;
    }
    @include pc-layout {
      width: 250px;
    }
  }

  * + &__content {
    margin-top: 24px;
  }

  &__content {
    margin-top: 24px;
    line-height: 2;
    font-size: 14px;
  }

  &__buttons {
    margin-top: 20px;
  }
}