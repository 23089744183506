@tailwind base;
@tailwind components;
//////////////////////////////////////

@import "variable";
@import "mixin";
@import "global";

// Component
@import "./TheHeader.component";
@import "./SearchBar.component";
@import "./components/UserIcon.component";
@import "./components/Tab.component";
@import "./components/ProductBalloon.component";
@import "./components/ListItem.component";
@import "./components/AddListItem.component";
@import "./components/TheProgress.component";
@import "./components/ErrorView.component";
@import "./components/HorizontalSingleRowSwiper.component";
@import "./components/PinBalloon.component";
@import "./components/DatePicker.component";
@import "./components/BasicSwiper.component";

// ATOMS
@import "./atoms/Cloud";
@import "./atoms/Pin";
@import "./atoms/BtnRect";
@import "./atoms/BtnMore";
@import "./atoms/BtnSns";
@import "./atoms/InputHashtag";
@import "./atoms/Icon";
@import "./atoms/Container";
@import "./atoms/ContainerAuth";
@import "./atoms/EditableContent";
@import "./atoms/BorderList";
@import "./atoms/SeparatedList";

//////////////////////////////////////
@tailwind utilities;
