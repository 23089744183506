.BtnRect {
  $border-width: 1px;

  display: inline-block;
  border-radius: 4px;
  text-align: center;
  padding: 0.8em 1.2em;
  white-space: nowrap;
  line-height: 1;
  transition: all linear 0.23s;
  border: $border-width solid #efefef;
  @apply font-bold text-black bg-gray-50;

  &:hover {
    text-decoration: none;

    @screen laptop {
      filter: brightness(0.9);
    }
  }

  /********
  * SIZE
  *********/
  &.small {
    @apply text-2xs;
    @screen laptop {
      @apply text-xs;
    }
  }

  &.middle {
    @apply text-sm;
  }

  &.large {
  }

  // プロダクト詳細内のリスト追加ボタン
  &.listInProduct {
    font-size: 16px;
    @screen laptop {
      font-size: 12px;
    }
  }

  /********
  * COLOR
  *********/
  &.white {
    @apply text-black;
    @apply bg-white;
  }

  &.gray {
  }

  &.red {
    @apply text-white bg-danger;
  }

  &.yellow {
    @apply text-black;
    @apply bg-yellow-500;
  }

  /********
  * ICON
  *********/

  &.hasIconList {
  }

  /********
  * STATE
  *********/
  &.selected {
    @apply text-white;
    background-color: #444;
  }

  &.fixed {
    @apply text-white;
    background-color: #444;
    opacity: 0.25;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
    background-color: #ccc;
  }
}
