.AddListItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  padding: 12px 24px;
  border-radius: 4px;
  overflow: hidden;
  + .AddListItem {
    margin-top: 16px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__name {
    position: relative;
    margin-right: 20px;
    color: #fff;
    font-size: 16px;
    .Icon {
      margin-right: 8px;
    }
  }

  &__count {
    position: relative;
    flex-shrink: 0;
    color: #fff;
    font-size: 12px;
  }
}