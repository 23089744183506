.Cloud {
  display: flex;
  flex-wrap: wrap;

  &.gap8 {
    > * {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }

  > * {
    display: inline-block;
    margin-bottom: 4px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
