.Container {
  position: relative;
  margin: 0 auto;
  min-height: calc(100vh - 64px - 60px);
  @include sp-layout {
    min-height: calc(100vh - 48px - 143px);
  }

  &.detail {
    max-width: 1280px;

    @include pc-layout {
      padding: 56px 40px 80px;
    }
  }

  // トップページ、リスト一覧、検索用
  &.archive {
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;

    @include sp-layout {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  // ユーザー、リストページ用
  &.user {
    padding-top: 56px;
    padding-bottom: 80px;

    @include sp-layout {
      padding-top: 40px;
      padding-bottom: 64px;
    }
  }
}
