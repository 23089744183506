.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker-year-header {
  @apply text-xs #{!important};
}

.react-datepicker__month-text {
  @apply font-bold;
  @apply m-4 text-xs #{!important};
}
