.Searchbar {
  position: relative;

  &__input {
    width: 100%;
    overflow: hidden;

    @include sp-layout {
      border-radius: 4px;
      padding: 4px 16px;
      line-height: 1.666;
      font-size: 16px;
    }

    @include pc-layout {
      padding: 0.6em 0.8em;
      border-radius: 4px;
      font-size: 12px;
    }
  }
}
