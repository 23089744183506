html {
  // ブラウザのデフォルトが16pxであることが前提
  font-size: 62.5%;
}

body {
  font-family:
    "Helvetica Neue", "Arial", "Hiragino Kaku Gothic ProN", "Hiragino Sans",
    "メイリオ", "Meiryo", "游ゴシック", "Yu Gothic", "MS Ｐゴシック", sans-serif;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  @apply text-base text-primary;
  line-height: 1.5;

  // ブラウザでのバウンススクロールを無効化
  overscroll-behavior-y: none;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

hr {
  @apply border-primary;
}

input,
textarea {
  font-size: 16px; /* この値を16pxより小さくすると、iOSでズームされてしまいます */
}

input::placeholder,
textarea::placeholder {
  @apply text-placeholder;
}

*[contenteditable],
input[type="text"],
input[type="password"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="url"],
textarea {
  @apply border border-secondary rounded-xs p-12;
  letter-spacing: 0.64px;

  &:focus {
    outline: none;
    @apply border border-yellow-500;
    box-shadow: 0 0 0px 4px rgb(255 212 0 / 0.3);
  }

  &:invalid {
    outline: none;
    @apply border border-red-500;
  }
}

button {
  &:focus {
    outline: 0;
  }
}

@layer utilities {
  .hidden-scrollbar {
    -ms-overflow-style: none;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    /* Firefox 対応 */
  }

  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }
}
