.TheHeader {
  position: relative;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
  font-size: 14px;

  &__logo {
    color: #000;
    line-height: 1;
    @include sp-layout {
      width: 100px;
    }

    @include pc-layout {
      width: 147px;
      height: 19px;
    }
  }

  &__search {
    @include sp-layout {
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 0 16px;
      z-index: 1;
    }

    @include pc-layout {
      flex: 1;
    }

    button {
      @include pc-layout {
        // display: none;
      }
    }
  }

  &__searchbar {
    @include sp-layout {
      flex: 1;
      @apply text-2xs;
    }

    @include pc-layout {
      flex: 1 0;
      font-size: 14px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @include sp-layout {
      @apply text-2xs;
    }

    .btnSearch {
      @include sp-layout {
        font-size: 21px;
      }

      @include pc-layout {
      }
      &:before {
        content: "\e90d";
      }
    }
  }

  &__posts {
    a {
      color: #888;
      &:hover {
        @include pc-layout {
          @apply text-black;
        }
      }

      &.nuxt-link-exact-active {
        @apply text-black;
      }
    }
  }

  &__options {
    position: absolute;

    @include sp-layout {
      $position: 8px;
      top: calc(100%);
      right: $position;
    }

    @include pc-layout {
      $position: 12px;
      top: calc(100% + #{$position});
      right: $position;
    }
  }
}
