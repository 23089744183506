.PinBalloon {
  $root: &;
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;

  @include pc-layout {
    padding: 8px;
  }

  &__tail {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 9px 0 9px;
    border-color: #000 transparent transparent transparent;
    left: 0;
  }
}
