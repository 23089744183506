.Pin {
  position: relative;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:before {
    display: block;
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 9999px;
    @apply bg-yellow-500;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: "";
    display: block;
    border-radius: 9999px;
    width: 12px;
    height: 12px;
    @apply border border-solid border-yellow-500;
    transition: opacity linear 1s;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  &:hover,
  &.animation {
    &:after {
      animation-name: pinAnimation;
      animation-iteration-count: infinite;
      animation-duration: 1s;
    }
  }
}

@keyframes pinAnimation {
  from {
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
