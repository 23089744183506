.InputHashtag {
  @apply flex items-stretch;

  &:before {
    content: '#';
    border: 1px solid #e5e5e5;
    border-right: none;
    background-color: #eee;
    border-radius: 4px 0 0 4px;
    @apply flex items-center justify-center px-12 font-bold;
  }

  input {
    border-radius: 0 4px 4px 0;
  }
}
